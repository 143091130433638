import useSWR from 'swr';
import { client } from '#/lib/api';
import { TypePlayerFields, TypeScoutingReportFields } from '#/lib/types';

export type Position =
  | TypeScoutingReportFields['position']
  | TypePlayerFields['position'];

export enum PositionCategory {
  Offense = 'offense',
  Defense = 'defense',
  SpecialTeams = 'special_teams',
  All = 'multi',
}

export type Trait = {
  name: string;
  description: string;
  positions: Position[];
  example?: string;
  prototype?: string;
  category?: PositionCategory;
};

export const options: Trait[] = [
  {
    name: 'Speedster',
    description:
      'This player has rare, break-away speed. A complete blur on the field. If you give them an inch, they can take a mile.',
    positions: ['RB', 'WR'],
    example: 'https://www.youtube.com/watch?v=xqFzSIaSxP4',
    prototype: 'Tyreek Hill',
    category: PositionCategory.Offense,
  },
  {
    name: 'Pass Rush Artist',
    description:
      'This player has an arsenal of pass rush moves and can win outside, inside, and through OLs.',
    positions: ['EDGE', 'DE', 'DL', 'DT', 'IDL', 'LB'],
    example: 'https://www.youtube.com/watch?v=_y-5htpws_Q',
    prototype: 'Von Miller',
    category: PositionCategory.Defense,
  },
  {
    name: 'Bendy',
    description:
      'This player can win with rare bendability off the edge. We are talking serious ankle flexion. Sweeping grass with their pass rush.',
    positions: ['EDGE', 'DE', 'IDL', 'LB'],
    example: 'https://www.youtube.com/watch?v=BYk718m8m-A',
    prototype: 'Von Miller',
    category: PositionCategory.Defense,
  },
  {
    name: 'Ball Hawk',
    description:
      'This player is always around the ball and makes their own turnover luck with elite instincts and intelligence.',
    positions: ['S', 'CB'],
    example: 'https://www.youtube.com/watch?v=DMmp67awAr0',
    prototype: 'Micah Hyde',
    category: PositionCategory.Defense,
  },
  {
    name: 'Rocket Arm',
    description:
      'This quarterback has elite arm strength and can stretch the field vertically as well as fit passes through tight windows',
    positions: ['QB'],
    example: 'https://www.youtube.com/watch?v=i4ul0eTmntk',
    prototype: 'Patrick Mahomes',
    category: PositionCategory.Offense,
  },
  {
    name: 'Bulldozer',
    description: `This player can move large masses with ease. A strongman from either side of the ball. "HE A LITTLE BOY TO ME!"`,
    positions: ['FB', 'TE', 'OT', 'OG', 'IOL', 'C', 'DT', 'IDL', 'DL'],
    example: 'https://www.youtube.com/watch?v=3Oy3uG3Qxik',
    prototype: 'Fletcher Cox',
    category: PositionCategory.All,
  },
  {
    name: 'YAC Monster',
    description:
      'This player excels running after the catch by breaking ankles or run through faces. Short catches can turn into chunk gains.',
    positions: ['WR', 'TE', 'RB', 'FB'],
    example: 'https://www.youtube.com/watch?v=P1icDmDI_98',
    prototype: 'CeeDee Lamb',
    category: PositionCategory.Offense,
  },
  {
    name: 'Sure Tackler',
    description:
      'This player is great at securing tackles and rarely gets evaded or broken. The type of player you want 1 on 1 with a RB in the flat.',
    positions: ['LB', 'CB', 'S'],
    prototype: 'Foye Oluokun',
    category: PositionCategory.Defense,
  },
  {
    name: 'Big Hitter',
    description:
      'This player packs a punch with each hit. Like a flying missile when attacking the ball carrier. The type of player that sets the tone defensively. Buckle those chin straps!',
    positions: ['LB', 'CB', 'S'],
    example: 'https://www.youtube.com/watch?v=LEPfAXtIHR4',
    prototype: 'Quincy Williams',
    category: PositionCategory.Defense,
  },
  {
    name: 'Precision Passer',
    description:
      'This quarterback can consistently deliver an accurate pass with the right touch. Fitting passes through windows and leading receivers into YAC.',
    positions: ['QB'],
    example: 'https://www.youtube.com/watch?v=rRNtoc5U-R4', // vs Raiders
    prototype: 'Justin Herbert',
    category: PositionCategory.Offense,
  },
  {
    name: 'Run Stuffer',
    description:
      'This defender is disruptive against the run. They live in the backfield. TFLs all day.',
    positions: ['EDGE', 'DE', 'DT', 'IDL', 'DL', 'LB'],
    prototype: 'Daron Payne',
    example: 'https://www.youtube.com/shorts/zyy-gOoomnI',
    category: PositionCategory.Defense,
  },
  {
    name: 'Contact Balance',
    description: `It is extremely hard to knock this player off balance. No matter from who or where the contact comes from, they always find a way to stay up.`,
    positions: ['RB', 'EDGE', 'DE', 'DT', 'IDL'],
    example: 'https://www.youtube.com/watch?v=9KPHXsnm9I8',
    prototype: 'Dameon Pierce',
    category: PositionCategory.Offense,
  },
  {
    name: 'Sure Handed',
    description:
      'This player rarely drops a pass in their catch radius. A reliable, high percentage target.',
    positions: ['WR', 'TE'],
    prototype: 'Mark Andrews',
    category: PositionCategory.Offense,
  },
  {
    name: 'Big Catch Radius',
    description: `This player can catch everything anywhere near them. Any angle, any height, it's catchable. Just throw it up.`,
    positions: ['WR', 'TE'],
    example: 'https://www.youtube.com/watch?v=aBSBm2Te-n4',
    prototype: 'George Pickens',
    category: PositionCategory.Offense,
  },
  {
    name: 'Relentless Motor',
    description:
      'This player plays with non-stop effort and energy and puts a ton of hustle plays on tape. Extra effort + Skill = More big plays',
    positions: ['EDGE', 'DE', 'DT', 'IDL', 'DL', 'LB'],
    example: 'https://www.youtube.com/watch?v=RhNzwtI9214',
    prototype: 'Micah Parsons',
    category: PositionCategory.Defense,
  },
  {
    name: 'Route Technician',
    description:
      'This player is a precision route runner and consistently loses defenders in and out of breaks',
    positions: ['WR', 'TE'],
    prototype: 'Garrett Wilson',
    example: 'https://www.youtube.com/watch?v=dKTPxRwPdW4',
    category: PositionCategory.Offense,
  },
  {
    name: 'Return Specialist',
    description: 'This player excels as a returner on kick and/or punt returns',
    positions: ['RB', 'WR', 'CB', 'S'],
    prototype: 'Devin Duvernay',
    category: PositionCategory.SpecialTeams,
  },
  {
    name: 'Dancing Bear',
    description:
      'This trench player has rare agility for their size. Their elite footwork gives them an immediate advantage up front.',
    positions: ['DT', 'IDL', 'DL', 'OT', 'OG', 'C', 'IOL'],
    prototype: 'Trent Williams',
    category: PositionCategory.Defense,
  },
  {
    name: 'Acrobat',
    description:
      'This player can contort their body to make spectacular plays when the balls in the air. Elite body control, flexibility, and ball skills.',
    positions: ['WR', 'CB', 'S'],
    example: 'https://www.youtube.com/watch?v=1S6JtcxFvUM',
    prototype: 'Odell Beckham Jr.',
    category: PositionCategory.All,
  },
  {
    name: 'Mobile Magician',
    description:
      'This player can make plays out of structure with their feet and/or their arms. When plays break down, they put the cape on.',
    positions: ['QB'],
    example: 'https://www.youtube.com/watch?v=LgzXlHmxYYM',
    prototype: 'Lamar Jackson',
    category: PositionCategory.Offense,
  },
  {
    name: 'Ultimate Weapon',
    description:
      'This player is a chess piece and can be used anywhere within the offense. They are physically rare. They run crispy routes and have great hands but they also have uncommon ability as a ball carrier and/or a blocker. Usually one of the best athletes on the field.',
    positions: ['RB', 'WR', 'TE'],
    example: 'https://www.youtube.com/watch?v=KXw3GrpuVYY',
    prototype: 'Deebo Samuel',
    category: PositionCategory.Offense,
  },
  {
    name: 'Ankle Breaker',
    description:
      'This player can change direction in an instant, leaving defenders grasping for air. Their elusiveness requires defenders to resort to Steph Curry level ankle braces.',
    positions: ['RB', 'WR'],
    example: 'https://www.youtube.com/watch?v=0rF_HWXSlnI',
    prototype: 'Dante Hall',
    category: PositionCategory.Offense,
  },
  {
    name: 'Plaster',
    description:
      'This player can lock up anyone man to man with elite technique and loose hips. Stickier than sticky.',
    positions: ['CB'],
    example: 'https://www.youtube.com/watch?v=khAsPTnFYA0',
    prototype: 'Darrelle Revis',
    category: PositionCategory.Defense,
  },
  {
    name: 'Battle Tested',
    description:
      'This quarterback has consistently produced against top competition in college. There is more of a chance they can survive at the next level.',
    positions: ['QB'],
    prototype: 'Joe Burrow',
    category: PositionCategory.Offense,
  },
  {
    name: 'Rangey',
    description: `This defender can cover a ton of ground in an instant and takes perfect angles to the ball. It's hard to escape their radius of impact on the field.`,
    positions: ['LB', 'S'],
    prototype: 'Darius Leonard',
    category: PositionCategory.Defense,
  },
  {
    name: 'Chaos Agent',
    description:
      'This defender specializes in causing disarray for opposing offenses often resulting in turnovers. Whenever they are around a play, something wild happens.',
    positions: ['EDGE', 'DE', 'DT', 'IDL', 'DL', 'LB', 'CB', 'S'],
    prototype: 'Troy Polamalu',
    example: 'https://www.youtube.com/watch?v=sNjnnihiQvA', // hufanga vs saints,
    category: PositionCategory.Defense,
  },
  {
    name: 'Power Runner',
    description:
      'This ball carrier runs is hard to bring down and is often running defenders over, tossing them aside, and falling forward for extra yards.',
    positions: ['RB', 'TE'],
    prototype: 'Najee Harris',
    example: 'https://www.youtube.com/watch?v=p8bajtMvW8A',
    category: PositionCategory.Offense,
  },
  {
    name: 'Vision Runner',
    description:
      'This ball carrier has special instincts as a runner and can read blocks and see gaps before they appear.',
    positions: ['RB'],
    prototype: 'Breece Hall',
    example: 'https://www.youtube.com/watch?v=rkahj7gmRpI',
    category: PositionCategory.Offense,
  },
  {
    name: 'Rare Size',
    description: `You can teach skills but you can't teach size. This player has rare physical measurables for their position.`,
    positions: [
      'QB',
      'FB',
      'RB',
      'WR',
      'TE',
      'OT',
      'OG',
      'IOL',
      'C',
      'EDGE',
      'DE',
      'DT',
      'IDL',
      'LB',
      'CB',
      'S',
    ],
    prototype: 'Tyron Smith',
    category: PositionCategory.All,
  },
  {
    name: 'Quick Get-Off',
    description:
      'The player gets off the ball in an instant, gaining an immediate advantage on opposing OLs.',
    positions: ['EDGE', 'DE', 'IDL', 'DL', 'DT'],
    prototype: 'Brian Burns',
    category: PositionCategory.Defense,
  },
  {
    name: 'Football IQ',
    description:
      'This player operates with rare instincts and awareness. They can diagnose the opposition quicker than most. They are rarely caught off guard and almost always in good position to make a play.',
    positions: [
      'QB',
      'FB',
      'RB',
      'WR',
      'TE',
      'OT',
      'OG',
      'IOL',
      'C',
      'EDGE',
      'DE',
      'DL',
      'DT',
      'IDL',
      'LB',
      'CB',
      'S',
    ],
    prototype: 'Fred Warner',
    category: PositionCategory.All,
  },
  {
    name: 'Hand-to-Hand Combat',
    description:
      'This player has quick and violent hands along with rare reflexes. They can quickly beat blockers by manipulating their opponent with expert handfighting.',
    positions: ['DL', 'DT', 'DE', 'EDGE', 'IDL'],
    prototype: 'Quinnen Williams',
    category: PositionCategory.Defense,
  },
];

type TraitFetchParams = {
  trait: string;
  position?: string;
};

export const fetchReportByTrait =
  ({ trait, position }: TraitFetchParams) =>
  async () => {
    let moreParams: object = {
      'fields.traits[in]': trait,
    };

    if (position) {
      moreParams = {
        ...moreParams,
        'fields.position': position,
      };
    }

    const { items, total } = await client.getEntries<TypeScoutingReportFields>({
      content_type: 'scoutingReport',
      include: 10,
      limit: 1000,
      ...moreParams,
    });

    return { items, total };
  };

export const useReportsByTrait = (trait: string, position?: string) =>
  useSWR(
    `${position ? `${trait}${position}` : trait}`,
    fetchReportByTrait({ trait, position }),
    { fallbackData: { items: [], total: 0 } }
  );

export const traitRateConfig = (number: number) => {
  const percent = parseFloat((number * 100).toFixed(2));
  switch (true) {
    case percent > 30:
      return {
        rarity: 'Common',
        percent,
      };
    case percent > 20:
      return {
        rarity: 'Uncommon',
        percent,
      };
    case percent >= 10:
      return {
        rarity: 'Rare',
        percent,
      };
    case percent < 10:
      return {
        rarity: 'Very Rare',
        percent,
      };
    default:
      return {
        rarity: 'Common',
        percent,
      };
  }
};
