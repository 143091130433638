import { createClient } from 'contentful';
import { TypePlayer } from './types';

export const CF_TOKEN =
  process.env.ALLOW_PREVIEW_CONTENT === 'true'
    ? process.env.PREVIEW_TOKEN
    : process.env.DELIVERY_TOKEN;

export const CF_HOST =
  process.env.ALLOW_PREVIEW_CONTENT === 'true'
    ? 'preview.contentful.com'
    : undefined;

export const client = createClient({
  space: process.env.SPACE_ID as string,
  accessToken: CF_TOKEN as string,
  host: CF_HOST as string,
});

export const previewClient = createClient({
  space: process.env.SPACE_ID as string,
  accessToken: process.env.PREVIEW_TOKEN as string,
  host: 'preview.contentful.com',
});

export const getClient = () =>
  process.env.ALLOW_PREVIEW_CONTENT === 'true' ? previewClient : client;

export const fetcher = <T>(...args: any[]): Promise<T> =>
  // @ts-ignore
  fetch(...args).then(res => res.json());

export const CMS_API_URL = 'https://admin.badlands.guide/api';

// export const patreonOAuthClient = patreon.oauth(
//   process.env.PATREON_CLIENT_ID,
//   process.env.PATREON_CLIENT_SECRET
// );

export const fetchReportsTotal = async () => {
  const { total } = await client.getEntries({
    content_type: 'scoutingReport',
    'metadata.tags.sys.id[in]': 'year2023',
  });

  return total;
};

export const fetchPositionalReportsTotal = (position: string) => async () => {
  const { total } = await client.getEntries({
    content_type: 'scoutingReport',
    'fields.position[in]': position,
    'metadata.tags.sys.id[in]': 'year2023',
  });

  return total;
};

export const fetchPositionPlayers = (position: string) =>
  fetcher<TypePlayer[]>(`/api/players/NYJ/${position}`);
