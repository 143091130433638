import { PositionCategory } from '#/utils/traits';
import { Theme } from 'bouqei';
import { darken, lighten } from 'polished';

const colors: Theme = {
  primary: '#007c50',
  secondary: '#00CC99',
  tertiary: '#247BA0',
  light: '#F5F5F5',
  primaryButtonText: '#FFFFFF',
  secondaryButtonText: '#FFFFFF',
  tertiaryButtonText: '#FFFFFF',
  white: '#FFFFFF',
  black: '#000000',
  dark: '#3A3A3A',
  links: '#8377D1',
  warning: '#9B2915',
  success: '#00CC99',
  accent: '#FFD972',
  grey: '#EAEAEA',
};

export default colors;

export const twitter = '#1da1f2';

export const darkPrimary = darken(0.05, colors.primary);

export const darkerPrimary = darken(0.15, colors.primary);

export const lightPrimary = lighten(0.05, colors.primary);

export const lighterPrimary = lighten(0.15, colors.primary);

export const darkSecondary = darken(0.05, colors.secondary);

export const darkerSecondary = darken(0.15, colors.secondary);

export const lightSecondary = lighten(0.25, colors.secondary);

export const lighterSecondary = lighten(0.55, colors.secondary);

export const lightLinks = lighten(0.15, colors.links);

export const lightTertiary = lighten(0.3, colors.tertiary);

export const lighterTertiary = lighten(0.55, colors.tertiary);

export const darkTertiary = darken(0.1, colors.tertiary);

export const darkerTertiary = darken(0.25, colors.tertiary);

export const darkLinks = darken(0.05, colors.links);

export const darkerLinks = darken(0.15, colors.links);

export const tan = '#f4f3ee';

export const positionCategoryColors = {
  [PositionCategory.All]: darkerLinks,
  [PositionCategory.Offense]: lightPrimary,
  [PositionCategory.Defense]: darkPrimary,
  [PositionCategory.SpecialTeams]: darkerPrimary,
};

export const gradeColors: Record<string, string> = {
  ['A+']: colors.primary,
  ['A']: colors.success,
  ['A-']: lightPrimary,
  ['B+']: '#00CED1	',
  ['B']: '#20B2AA',
  ['B-']: '#008080',
  ['C+']: '#FFD700',
  ['C']: '#FFA500',
  ['C-']: '#FF8C00',
  ['D+']: '#FF7F50',
  ['D']: '#FF4500',
  ['D-']: '#CD5C5C',
  ['F']: '#FF0000',
};

export const needColors: Record<string, string> = {
  ['Very Low']: '#00CED1',
  Low: '#20B2AA',
  Medium: '#FFA500',
  High: '#FF4500',
  ['Very High']: '#FF0000',
};

export const rarityColors: Record<string, string> = {
  ['Common']: colors.dark,
  ['Uncommon']: colors.secondary,
  ['Rare']: '#1789FC',
  ['Very Rare']: '#9d4dbb',
};
